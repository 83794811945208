.home-slideshow {
	@apply .relative .mx-1 .mx-auto {}
	max-width: 1400px;
	@screen xxl {
		@apply .mx-auto {} }
	.slick-dots {
		@apply .flex .z-20 .mx-auto .mt-05 .px-1 {}
		max-width: 400px;
		@screen changenav {
			@apply .px-0 {} }

		li {
			@apply .flex-1 {}
			overflow: hidden;
			&:not(.slick-active) {
				opacity: 0.15; } }

		button {
			@apply .bg-black .w-full {}
			margin: 0 1px;
			padding: 0;
			height: 5px;
			text-indent: 1000%;
			&:focus {
				outline: none; } } }

	.caption {
		@apply .absolute .bg-black .text-white .z-20 .font-subheadings .p-05 .leading-none {}
		@screen md {
			@apply .text-xl {}
			bottom: 2rem;
			left: 2rem;
			max-width: 250px; }
		@screen lg {
			@apply .text-xxl {}
			max-width: 400px; }
		@screen mobile {
			bottom: 0;
			left: 0;
			right: 0; }

		&.video-caption {
			opacity: 0;
			transform: translateX(-100%);
			transition: all 0.6s $easeInOutExpo;

			&.show {
				opacity: 1;
				transform: translateX(0); } } }

	iframe {
		width: 100%;
		height: 100%; }


	.video-wrapper {
		position: relative;
		padding-bottom: 56.25%;
		/* 16:9 */
		height: 0;
		cursor: pointer;

		i {
			position: absolute;
			top: 50%;
			left: 50%;
			font-size: 4rem;
			transform: translate(-50%, -50%);
			z-index: 99;
			color: white;
			opacity: 0.75; }

		.play-control {
			@apply .absolute .inset-0 {}
			background: rgba(0,0,0,0.2); } }


	.video-wrapper iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%; } }

.intro {
	@apply .text-lg .text-center {}
	@screen md {
		@apply .py-4 {} } }
