.container {
	@apply .px-1 {}
	max-width: 1200px;
	margin: 2rem auto;

	&.template-center {
		@apply .text-center {} } }

.banner-message {
	@apply .sticky .text-center .py-05 .px-1 {}
	a {
		@apply .underline {} }
	@screen mobile {
		@apply .text-sm {} } }

.breadcrumbs {
	@screen mobile {
		display: none; }
	@apply .my-2 {}
	ul {
		@apply .text-grey .text-sm .flex {}
		li {
			@apply .px-1 {} }
		li:first-child {
			padding-left: 0; } } }

.info-bar {
	@apply .my-1 .border-t .border-b .border-lightgrey .py-05 {}
	@screen md {
		@apply .my-2 .flex .justify-around {} }
	i {
		@apply .px-05 {}
		min-width: 2rem;
		@screen md {
			min-width: 0; } }

	.share-links {
		a {
			@apply .px-05 .py-025 {} }
		i {
			@apply .px-0 .mr-05 {} } } }

.two-col {
	@screen sm {
		@apply .grid .grid-cols-2 .gap-2 {} }
	@screen mobile {
		& > div:not(:last-of-type) {
			@apply .mb-1 {} } } }

.three-col {
	@screen sm {
		@apply .grid .grid-cols-2 .gap-2 {} }
	@screen md {
		@apply .grid .grid-cols-3 .gap-2 {} }
	@screen mobile {
		& > div:not(:last-of-type) {
			@apply .mb-1 {} } } }

.btn,
button {
	@apply .px-1 .py-05 .uppercase .bg-black .text-white {}
	text-decoration: none !important;
	@screen md {
		@apply .px-2 {} } }

.card {
	.card-title {
		@apply .leading-tight .my-05 .py-05 .px-1 .relative .text-left {}
		transition: all 0.3s $easeInOutQuart;
		&::after {
			@apply .absolute .bg-black .h-full .block {}
			content: '';
			left: 0;
			top: 0;
			width: 1px;
			z-index: -1;
			transition: all 0.3s $easeInOutQuart; }
		h2 {
			@apply .mb-0 {} }
		.date {
			@apply .text-grey .text-sm {} } }
	&:hover .card-title {
		@apply .text-white {}
		&::after {
			width: 100%; } } }


.cta-link {
	&-left,
	&-center,
	&-right {
		@apply .flex {}
		a,
		button {
			@apply .relative .inline-block .mt-1 .px-1 {}
			z-index: 0;
			transition: all 0.3s $easeInOutQuart;
			@screen md {
				@apply .mt-2 {} }
			&::after {
				@apply .absolute .bg-black .h-full .block {}
				content: '';
				right: 0;
				top: 0;
				width: 1px;
				z-index: -1;
				transition: all 0.3s $easeInOutQuart; }
			&:hover {
				@apply .text-white {}
				&::after {
					width: 100%; } } }
		&.on-black {
			a,
			button {
				&::after {
					@apply .bg-white {} }
				&:hover {
					@apply .text-black {} } } } }

	&-left {
		@apply .justify-start {} }
	&-center {
		@apply .justify-center {} }
	&-right {
		@apply .justify-end {} } }


.spinner {
	img {
		margin: 0 auto;
		max-width: 80px;
		animation: spin 7s linear infinite; } }

@keyframes spin {
	from {
		transform: rotate(0deg); }
	to {
		transform: rotate(360deg); } }

.store-opening-hours {
	max-width: 250px; }

.index {
	.category {
		& > div {
			@apply .pb-05 .pt-2 .table-cell {}
			@screen mobile {
				@apply .hidden {} }
			&:first-child {
				@apply .table-cell {} } } }

	.store {
		transition: background 0.3s ease;

		.store-name a {
			@apply .pr-1 {} }
		&:hover {
			@apply .bg-black .text-white {}

			.store-name a {
				@apply .pl-1 .pr-0 {} } }

		@screen mobile {
			@apply .py-05 .flex .flex-wrap {}
			.store-info {
				@apply .text-sm {} }
			.store-name {
				flex-basis: 100%; } }

		& > div {
			@screen md {
				@apply .table-cell {} } } }

	.category,
	.store {
		@screen mobile {
			@apply .border-b .border-lightgrey {} }
		@screen md {
			@apply .table-row {} }
		& > div {
			@screen md {
				@apply .py-1 .border-b .border-lightgrey {} } }

		span {
			@apply .px-05 {}
			@screen md {
				@apply .px-1 {} } } }

	.store-name a {
		transition: all 0.3s ease; } }

#search {
	@apply .fixed .inset-0 .bg-black .flex .flex-col .items-center {}
	z-index: 99;

	.close {
		@apply .absolute .text-white .text-xl .leading-none .cursor-pointer {}
		top: 1rem;
		right: 1rem; }


	.search-input {
		@apply .w-full .relative {}
		max-width: 800px;
		margin-top: 10%;

		input {
			@apply .bg-transparent .text-white .border-b-2 .border-white .w-full .text-xl {} }

		.loading {
			@apply .absolute .text-white {}
			right: 0;
			top: 50%;
			transform: translateY(-50%);
			opacity: 0;
			transition: opacity 0.3s ease;

			&.active {
				opacity: 1; } }

		.fa-spin {
			animation-duration: 0.5s; } }

	.search-results {
		@apply .text-white .w-full .my-2 {}
		max-width: 800px;

		p.no-result {
			@apply .py-1 {} }

		li.result {
			@apply .py-1 .border-b .border-grey .flex .justify-between .items-end {}

			span.type {
				@apply .text-sm .uppercase .text-grey {} } } } }

.the-content {
	a {
		text-decoration: underline; } }


.form-fields {
	@screen md {
		@apply .grid .grid-cols-2 .gap-2 {} } }

.field {
	@apply .my-1 {}
	label {
		@apply .block .py-05 {} }

	input,
	textarea {
		@apply .border-b .border-grey .w-full .text-left {} }

	select {
		@apply .appearance-none .border-b .border-grey .w-full .bg-white .rounded-none {} }

	button.wait {
		@apply .pointer-events-none {} } }


.parsley-errors-list {
	@apply .text-red {} }

.side-logo {
	@apply .fixed {}
	left: 2rem;
	z-index: 99;
	top: 50%;
	transform: translateY(-50%);
	display: none;
	@screen xl {
		display: block; } }

.side-back-to-top {
	@apply .fixed {}
	right: 2rem;
	z-index: 99;
	top: 50%;
	transform: translateY(-50%);
	display: none;
	@screen xl {
		display: block; } }

.narrow {
	max-width: 500px; }

body.modal-open {
	header {
		z-index: 0; } }

.modal {
	@apply hidden {}

	&.is-open {
		@apply block {} }

	&-overlay {
		@apply fixed inset-0 flex justify-center {}
		z-index: 999;
		background: rgba(white,0.8); }

	&-container {
		@apply my-auto border border-black bg-white p-2 flex flex-col justify-between {}
		width: 90%;
		height: 90%;
		max-width: 500px;
		max-height: 500px; }

	&-inner {
		@apply my-auto {} }

	form {
		@apply flex justify-center flex-col mx-auto {}
		max-width: 300px;
		input {
			@apply p-1 text-center {} } } }
