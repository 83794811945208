#competition {
	@apply .w-full .p-1 .flex .flex-col .justify-center {}
	min-height: 100vh;
	background-size: cover;
	background-position: top center;

	.form-wrapper {
		max-width: 700px;
		background: rgba(255,255,255,0.5);
		@apply .px-1 .pt-1 .pb-1 .font-subheadings .italic {}
		@screen md {
			@apply .px-3 .pt-1 .pb-2 {} } }

	form {
		.input-wrap {
			@apply .relative .mb-1 {}
			i.fa-chevron-down {
				@apply .absolute {}
				top: 50%;
				right: 1rem;
				transform: translateY(-50%); } }

		input[type="text"],
		input[type="email"],
		select {
			@apply .border-2 .border-black .px-1 .py-05 .rounded-none .bg-white .leading-none .text-lg .w-full .font-subheadings {}
			height: 3.5rem;
			&::placeholder {
				@apply .text-black .italic {} } }

		select {
			@apply .appearance-none {} }

		input[type="checkbox"] {
			display: none;
			&:checked + span.checkbox {
				@apply .text-black {} } }

		.checkbox {
			@apply .border-2 .border-black .px-05 .mr-1 .bg-white {}
			width: 1rem;
			color: transparent; }

		button {
			@apply .bg-black .text-white .text-center .p-1 .block .w-full .text-lg .italic {} } }


	.footer-logo {
		@apply .mt-2 {}
		max-width: 150px; }

	.parsley-error,
	.parsley-error span.checkbox {
		border-color: red !important; }

	.parsley-errors-list {
		display: none; }


	.modal-link {
		cursor: pointer; }

	.modal {
		display: none;
		pointer-events: none;
		&.is-open {
			display: block;
			pointer-events: all; }

		.modal-overlay {
			@apply .fixed .inset-0 .flex .justify-center .items-center .z-10 .text-sm {}
			background-color: rgba(0,0,0,0.5);
			cursor: pointer; }

		.modal-container {
			@apply .p-2 .bg-white {}
			@screen md {
				@apply .p-4 {} }
			max-width: 800px;
			max-height: 90vh;
			overflow-y: auto;
			cursor: default;

			h2 {
				@apply .text-lg .text-center .font-bold .mb-2 {} }

			p {
				@apply .my-1 {} }


			button {
				@apply .bg-black .py-1 .px-2 .text-white .mt-2 .uppercase .font-bold {} }

			li {
				@apply .my-05 .ml-1 {}
				list-style-type: disc; } } }


	h1, h2, h3, h4 {
		@apply .text-xl .mb-1 .leading-snug .text-left .font-subheadings .normal-case {}
		@screen md {
			@apply .text-xxl {} } }
	span {
		@apply .font-headings .not-italic .font-bold {} } }
