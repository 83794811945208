.footer-signup {
	@apply .relative {}
	z-index: 0;
	@screen md {
		@apply .mt-4 {} }
	&::after {
		@apply .absolute .w-full .bg-lightgrey {}
		content: "";
		height: 50%;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: -1; }
	.signup-wrapper {
		@apply .py-2 {}
		@screen md {
			@apply .py-4 {} } }
	.container {
		@apply .mb-0 {}
		@screen mobile {
			@apply .px-0 {} } }

	form {
		@apply .w-full .mx-auto .px-1 {}
		max-width: 600px; }

	input[type="email"] {
		@apply .font-subheadings .text-lg .border-b .text-center .border-grey .w-full {}
		&::placeholder {
			@apply .text-grey {} } } }

footer {
	@apply text-sm {}
	h4 {
		@screen md {
			@apply .mb-1 {} } }

	.container {
		@apply .my-0 .py-2 {}
		@screen md {
			@apply .py-6 {} } }

	.footer-grid {
		@apply .grid .grid-cols-1 .justify-between {}
		@screen md {
			@apply .gap-3 {} }
		@screen changenav {
			@apply .grid-cols-3 {} }
		@screen mobile {
			@apply .flex-col .items-center {} } }

	.footer-nav {
		ul {
			@apply .uppercase {}
			column-count: 2;
			li {
				@apply .mb-05 {} } } }

	.social {
		@apply .mt-2 .text-center {}
		@screen mobile {
			@apply .mt-2 {} }
		a {
			@apply .text-lg .px-05 {}
			@screen md {
				@apply .pr-1 {} } } }

	svg {
		width: 100%;
		max-width: 180px; }

	.sub-footer {
		@apply .mt-2 .text-grey .text-sm .text-center {}
		@screen md {
			@apply .flex .justify-center {} }
		p {
			@apply .mx-05 {}
			@screen md {
				@apply .mx-1 {} } } } }
