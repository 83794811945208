//base html stuff
html,
body {
	@apply .bg-white .text-black .font-body .antialiased {}
	-webkit-overflow-scrolling: touch;
	text-rendering: optimizeLegibility;
	font-size: 100%; }

* {
	box-sizing: border-box;
	&:focus {
		outline: none; } }

img {
	display: block;
	width: 100%; }

#preload-bar {
	@apply .bg-white .fixed .w-full .top-0 .left-0 .right-0 {}
	z-index: 300;
	height: 3px;

	svg {
		height: 3px; }

	path {
		@apply .stroke-current .text-black {} } }

#preload-counter {
	@apply .fixed .top-0 .left-0 .mt-1 .ml-1 .pointer-events-none {}
	z-index: 300; }

#preload-overlay {
	@apply .bg-white .fixed .w-full .h-full .top-0 .left-0 .flex .items-center .justify-center {}
	z-index: 200;
	.logo-loading {
		width: 120px;
		height: auto;

		&.logo-animated {
			.outer,
			.arm-line-1,
			.arm-line-2,
			.inner {
				animation: offset 5s infinite forwards;
				stroke-dasharray: 500px;
				stroke-dashoffset: 500px; }
			.circle {
				animation: offsetRotate 5s infinite forwards;
				transform-origin: 50% 50%;
				stroke-dasharray: 500px; } } } }

#barba-wrapper {
	@apply .relative .z-10 {} }

.barba-container {
	@apply .w-full {} }

@keyframes offset {
	to {
		stroke-dashoffset: 0px; } }

@keyframes offsetRotate {
	from {
		stroke-dashoffset: 500px;
		transform: rotate(-360deg); }

	to {
		stroke-dashoffset: 0px; } }
