header {
	@apply .py-05 .sticky .px-1 .z-20 .bg-white .border-transparent {}
	transition: border 0.3s ease;
	top: 0;
	@screen md {
		@apply .pt-15 .px-2 {} }
	.logo {
		text-align: center;
		pointer-events: none;
		@screen changenav {
			@apply .absolute .w-full {}
			left: 50%;
			transform: translateX(-50%);
			z-index: 99;
			a {
				@apply .inline-block {}
				@screen changenav {
					min-width: 300px;
					pointer-events: all; } } }

		.logo-circle {
			max-width: 50px;
			margin: 0 auto;
			@screen sm {
				max-width: 60px; } }

		.logo-text {
			@apply .relative {}
			max-width: 200px;
			margin: 0.5rem auto 0;
			transition: all 0.3s ease;
			display: none;
			@screen sm {
				max-width: 250px; }
			@screen md {
				margin: 1rem auto 0; }
			@screen changenav {
				display: block; } } }

	&.scrolled {
		@apply .border-b .border-lightgrey {}
		.logo-text {
			opacity: 0; } }

	.top-right {
		@apply .absolute {}
		top: 1rem;
		right: 2rem;

		@screen mobile {
			display: none; }

		.opening-hours {
			@apply .text-grey {} }

		.social-links {
			@apply .mt-05 {}
			text-align: right;
			a {
				@apply .ml-1 .text-lg {} } } }

	.nav-trigger {
		display: none;
		@screen mobile {
			@apply .block .absolute .z-50 .text-lg .leading-none {}
			top: 50%;
			transform: translateY(-50%);
			left: 1rem; } }

	&.scrolled {
		@screen changenav {
			nav {
				padding-top: 60px; } } } }

nav {
	@apply .flex .justify-center .relative .mx-auto {}
	max-width: 1400px;
	transition: padding 0.3s ease;
	@screen mobile {
		@apply .flex .fixed .inset-0 .bg-white .z-40 .text-lg {}
		transform: translateX(-100%);
		transition: all 0.3s $easeInOutExpo;
		&.active {
			transform: translateX(0%); } }

	@screen changenav {
		padding-top: 100px; }

	ul.nav {
		@apply .flex .uppercase .justify-center {}
		@screen mobile {
			@apply .justify-center .flex .items-center .flex-col {}
			li {
				@apply .py-05 {} } }
		@screen md {
			@apply .mt-15 .mb-1 {} }

		li {
			@apply .mr-1 {}

			a {
				@apply .font-bold {} }

			&.active > a {
				@apply .border-b-2 .border-black {} }

			&.active-child > a {
				@apply .border-b-2 .border-black {} } } }

	.search {
		@apply .absolute .cursor-pointer {}
		right: 0;
		@screen md {
			bottom: 0;
			transform: translateY(-50%); }
		@screen mobile {
			top: 1rem;
			right: 1rem; }

		i {
			@apply .text-lg {} } } }
