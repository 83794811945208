#store {

	.store-details {
		@apply .flex .flex-wrap .pt-2 {}

		.hours {
			flex: 1 1 auto;
			@screen md {
				flex: 1 0 300px; } }
		.map {
			flex: 1 1 auto;
			@apply .pt-2 .relative {}
			@screen md {
				@apply .pl-2 .pt-0 {}
				flex: 1 1 60%; }

			#location_vector_img {
				@apply .absolute {}
				mix-blend-mode: multiply;

				.vector-store {
					fill: none;
					stroke: #000;
					stroke-miterlimit: 10;

					&.active {
						fill: darkgrey;
						animation: beacon 2s infinite; } } } } } }

@keyframes beacon {
	0% {
		opacity: 0; }
	50% {
		opacity: 1; }
	100% {
		opacity: 0; } }
