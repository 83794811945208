//typography
h1, h2, h3 {
	@apply .font-headings .text-lg .mb-1 {} }

h1 {
	@apply .uppercase .text-center {} }

h2 {
	@apply .font-subheadings {}

	&.alt {
		@apply .font-headings .uppercase .text-center {} } }

h4 {
	@apply .uppercase .font-bold {} }

.the-content {
	p {
		@apply .mb-1 {} }

	ul {
		@apply .ml-2 .mb-1 {}
		list-style-type: disc;
		li {
			@apply .pl-05 {} } } }

label {
	@apply .text-sm .uppercase {} }
